<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-brandprofile', params: { accountId: this.$route.params.accountId } }">BrandProfiles</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <h1 class="text-h6 font-weight-light text-center">Do you already own the BrandProfile?</h1>
                <!-- <h1 class="text-h6 font-weight-light text-center">{{ accountName }}</h1> -->
                <!-- <p class="text-caption text-center">Add a brandProfile</p> -->
                <!-- <p class="grey--text text--darken-2 mx-6 mt-6">Yes: skip to "manage a registered brandProfile".</p>
                <p class="grey--text text--darken-2 mx-6">No: go to "register a new brandProfile", then come back to this page to "manage a registered brandProfile"</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- Add an existing brandProfile (requires verification) -->
                    <v-card tile elevation="4" class="pa-0 mt-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">Yes</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <p>You will need to verify ownership of the BrandProfile before it is added to your account.</p>

                            <v-alert dense type="info" border="left">
                                <template v-slot:prepend>
                                    <font-awesome-icon fixed-width icon="info-circle" class="white--text text-body-1 mr-2"/>
                                </template>
                                <span class="text-body-1">Please contact Emplus customer support for this. Self-service verification is coming soon!</span>
                            </v-alert>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- TODO: instead of redirecting to averify page, the continue button needs to start the webauthz access request here and then redirect user to the url provided by the server when it responds -->
                            <!-- <v-btn elevation="4" class="green white--text" :to="{ name: 'account-create-brandprofile-verify', params: { accountId: this.$route.params.accountId } }">
                                <span>Continue</span>
                            </v-btn> -->
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    <!-- Register new brandProfile -->
                    <v-card tile elevation="4" class="pa-0 mt-8 mb-6">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">No</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <p>Create a new <TextLink href="https://brandprofile.org" target="_blank">BrandProfile</TextLink>, then return to this page to add it to your account.</p>
                        </v-card-text>
                        <!-- <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>Where can I register a new brandProfile?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="text-caption">
                                        <font-awesome-icon :icon="['fas', 'info-circle']" class="blue--text"/>
                                        This information is provided for your convenience only. We do <em>NOT</em> make any endorsement about the reliability or customer experience for any of these registrars. Check ratings and reviews before selecting a registrar.</p>
                                    <p><TextLink href="https://godaddy.com" target="_blank">GoDaddy</TextLink></p>
                                    <p><TextLink href="https://www.namecheap.com" target="_blank">Namecheap</TextLink></p>
                                    <p><TextLink href="https://duckduckgo.com/?q=best+brandProfile+registrars" target="_blank">Find more...</TextLink></p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels> -->
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { isValidName, compact } from '@/sdk/input';
import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        TextLink,
    },
    data: () => ({
        account: null,
        error: null,
        newDomainName: null,
        newDomainNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Domain name is required',
        ],
        submitActionTimestamp: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isAddFormComplete() {
            // TODO: check that brandProfile name is not too similar to existing brandProfile name
            return typeof this.newDomainName === 'string' && this.newDomainName.trim().length > 0;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async create() {
            try {
                if (Number.isInteger(this.submitActionTimestamp) && this.submitActionTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitActionTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { createDomain: true });
                console.log('createDomain');
                const request = {
                    // name: this.name,
                    name: this.newDomainName,
                    // agreeToTerms: this.isAgreeToTermsChecked,
                    // interactionId: this.interactionId, // will be present if brandProfile arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the brandProfile to an appropriate location after brandProfile is created
                };
                const response = await this.$client.account(this.$route.params.accountId).brandProfile.create(request);
                console.log('createDomain response: %o', response);
                const { isCreated, error } = response;
                if (!isCreated && error) {
                    switch (error) {
                    case 'DOMAIN_VERIFICATION_REQUIRED':
                        this.$router.push({ name: 'account-verify-brandprofile', params: { accountId: this.$route.params.accountId, brandProfile: this.newDomainName } });
                        break;
                    case 'DOMAIN_ALREADY_CLAIMED':
                        this.$router.push({ name: 'account-dispute-brandprofile', params: { accountId: this.$route.params.accountId, brandProfile: this.newDomainName } });
                        break;
                    case 'UNKNOWN':
                    default:
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create brandProfile', message: 'Unknown error' });
                        break;
                    }
                }
                if (isCreated) {
                    this.newDomainName = null;
                    this.$router.push({ name: 'account-view-brandprofile', params: { accountId: this.$route.params.accountId, brandProfile: this.newDomainName } });
                }
            } catch (err) {
                console.log(`failed to create brandProfile, sattus is ${err.response.status}`);
                if (err.response?.status === 409) {
                    // brandProfile already exists in account, redirect user to brandProfile verification page
                    this.$router.push({ name: 'account-verify-brandprofile', params: { accountId: this.$route.params.accountId, brandProfile: this.newDomainName } });
                }
                console.error('failed to create brandProfile', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { createDomain: false });
            }
        },
        cancel() {
            this.$router.go(-1);
        },
    },
    mounted() {
        this.loadAccount();
    },
};
</script>
